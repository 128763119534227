import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import { Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Formação de Agentes  </span> <br></br>
             de Inseminação Artificial<br></br> em bovinos
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal7} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Rui d´Orey Branco
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor de Bovinos de Leite
                      </div>
                      </div>
                    
                  </div>

                  {/* <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal12} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Nestor Chagas e Silva
                      </div>
                    
                    <div className="titulo inter-light">
                     Professor e Consultor de reprodução em ruminantes
                      </div>
                      </div>
                    
                  </div> */}
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Benavente, Santarém
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      
                Brevemente
               
                
                      
                      </div>
             
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      700€+IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                {/* <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/IA.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> </a>
                </div> */}
                <div className="form">
                {/* <a href="https://forms.gle/bJQRptUrGkTJUPns6" target="_blank" >

                <button id="second-app-title" className="btn-form inter-bold">Inscrição</button> 
                <ReactTooltip
        anchorId="second-app-title"
        place="bottom"
        variant="info"
        content="Este curso não é continuo, verifique o programa detalhado."
      />
                </a> */}
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              O curso de agentes de inseminação artificial em bovinos,
tem como objetivo partilhar conhecimento sobre
endocrinologia e ciclo éstrico. Contempla também, todo o
manuseamento e armazenagem do sémen, bem como a sua
preparação até ao momento de inseminação. Para além da
componente teórica, a componente prática é dividida em
práticas de inseminação em peças reais e em animais vivos.
No final do curso, é feito um exame de aprovação pela
DGAV.
                </div>
                <div className="logos">
                  
                <img src={logo5} alt="" />
                <img src={logo6} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
  
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - DATA A DEFINIR - TEÓRICA PRESENCIAL I
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">- Apresentação do curso <br></br>
- História e definição da IA em bovinos <br></br>
- Exercício de co-teaching sobre fisiologia da
reprodução<br></br>
- Biossegurança e doenças com influência direta
na reprodução - Papel do inseminador</span></p>
                                </div>       
                             
                               
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>  
                             
                             
          
                                <div class="modulo">
                                <p><span className="date">14:30 - 19:30</span> | <span class="mod">Módulo 2</span> - <span class="txt">- Higiene do equipamento de IA - Desinfeção,
desinfetantes e noções de microbiologia<br></br>
- Estudo e manipulação de aparelhos genitais recolhidos
em matadouro com modelo de palpação</span></p>
                                </div>    

                                                 
      </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - DATA A DEFINIR - TEÓRICA PRESENCIAL II
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">- Noções de anatomo-fisiologia genital
da fêmea bovina<br></br>
- Ciclo reprodutivo e ciclo éstrico da fêmea
bovina <br></br>
- Azoto líquido e recipientes criobiológicos -
Preparação da dose seminal, maneio e aplicação
de sémen congelado bovino</span></p>
                                </div>       
                               
                             
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>  
                                <div class="modulo">
                                <p><span className="date">14:30 - 19:30</span> | <span class="mod">Módulo 2</span> - <span class="txt">- Tecnologia do sémen bovino - Colheita, processamento,
conservação e distribuição <br></br>
- Seleção genética e emparelhamento em 2023
(Palestrante convidado Dr. José Ferrão)<br></br>
- Elaboração do processo de licenciamento do centro
de armazenagem de sémen de bovino
</span></p>
                                </div>   
                               

                              

          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 3 - DATA A DEFINIR - INSEMINAÇÃO EM EXPLORAÇÃO I - BARÃO & BARÃO, LDA
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">07:30 - 18:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Prática de inseminação artificial e manuseamento de
sémen em exploração</span></p>
                                </div>                               
                               </Accordion.Body>
                               </Accordion.Item>
                          
                               <Accordion.Item eventKey="3">
                                <Accordion.Header>
                            
                            +  Dia 4 - DATA A DEFINIR - INSEMINAÇÃO EM EXPLORAÇÃO II - BARÃO & BARÃO, LDA
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">07:30 - 18:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Prática de inseminação artificial e manuseamento de
sémen em exploração </span></p>
                                </div> 
                               </Accordion.Body>
                               </Accordion.Item>

                               <Accordion.Item eventKey="4">
                                <Accordion.Header>
                            
                            +  Dia 5 - DATA A DEFINIR - INSEMINAÇÃO EM EXPLORAÇÃO III - BARÃO & BARÃO, LDA
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">07:30 - 18:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Prática de inseminação artificial e manuseamento de
sémen em exploração </span></p>
                                </div> 
  
    
                               </Accordion.Body>
                               </Accordion.Item>

                               <Accordion.Item eventKey="5">
                                <Accordion.Header>
                            
                            +  Dia 6 - DATA A DEFINIR - INSEMINAÇÃO EM EXPLORAÇÃO IV - BARÃO & BARÃO, LDA
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">07:30 - 18:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Prática de inseminação artificial e manuseamento de
sémen em exploração</span></p>
                                </div> 
  
                           
                               </Accordion.Body>
                               </Accordion.Item>

                               <Accordion.Item eventKey="6">
                                <Accordion.Header>
                            
                            +  Dia 7 - DATA A DEFINIR - AULA PRÉ-EXAME
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">07:30 - 18:00</span> | <span class="mod">Módulo 7</span> - <span class="txt">Aula de preparação para exame de componente
teórica e prática </span></p>
                                </div> 
  
                          
                               </Accordion.Body>
                               </Accordion.Item>
                       
                       

                      

                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        {/* <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/IA.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div> */}
                {/* <div className="form">
                <a href="https://forms.gle/bJQRptUrGkTJUPns6" target="_blank" >

                <button id="second-app-title2" className="btn-form inter-bold">Inscrição</button> 
                <ReactTooltip
        anchorId="second-app-title2"
        place="bottom"
        variant="info"
        content="Este curso não é continuo, verifique o programa detalhado."
      />
                </a>

                
          </div> */}
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal7} alt="" />

              </div>
              <div className="nome">
              Rui d´Orey Branco
              </div>
              <div className="titulo">
                 Consultor de Bovinos de Leite
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Chefe de serviços de melhoramento animal

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
Professor na Universidade Lusófona
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Doutorado em fisiologia reprodutiva - Texas AM
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Awarded-Ronnie excellence - Teaching award
</p>
                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
{/* 
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal12} alt="" />

              </div>
              <div className="nome">
                Nestor Chagas e Silva
              </div>
              <div className="titulo">
                  Professor e Consultor de reprodução em ruminantes
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Professor – Universidade de Lisboa
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Departamento de Reprodução e Obstetrícia da Faculdade de Medicina Veterinária da Universidade de Lisboa</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Foi técnico especialista na Estação Nacional de Seleção e Reprodução Animal entre 1983 e 2007</p>
           
                 
                  </div>
              </div>
            </div>
        </div>
        </div> */}
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
